/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 */

import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { withBlockExtensions } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers';
import cx from 'classnames';

import logoLeft from '../../../../../../../images/SID-white.svg';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = (props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { data, className } = props;
  let videoID = null;

  const previewImage = data?.preview_image;
  console.log(previewImage);

  let placeholder = previewImage
    ? isInternalURL(previewImage)
      ? `${previewImage}/@@images/image`
      : previewImage
    : null;

  const handlePlaceholderClick = () => {
    if (!data.url) {
      return;
    }
    setShowPlaceholder(false);
  };

  if (data.url) {
    videoID = data.url.match(/.be\//)
      ? data.url.match(/^.*\.be\/(.*)/)[1]
      : data.url.match(/^.*\?v=(.*)$/)[1];
  }

  console.log('auotplay is', data?.autoPlay);

  return (
    <div
      className={cx(
        'block video align',
        {
          center: !Boolean(data.align),
        },
        data.align,
        className,
      )}
    >
      <figure
        className={cx('video-inner', {
          'full-width': data.align === 'full',
        })}
      >
        <div className="video-container">
          {showPlaceholder && (
            <img
              className="image-placeholder"
              src={placeholder}
              alt="video-placeholder"
              onClick={handlePlaceholderClick}
              style={{ display: showPlaceholder ? 'block' : 'none' }}
            />
          )}
          <a href="/" className="logo-container">
            <img className="logo-header" src={logoLeft} alt="logoLeft" />
          </a>
          {data.url && (
            <iframe
              src={`https://www.youtube.com/embed/${videoID}?autoplay=${
                data.autoPlay ? 1 : 0
              }&mute=1&loop=${
                data?.loop ? 1 : 0
              }&controls=0&enablejsapi=1&showinfo=0&playlist=${videoID}`}
              allow="autoplay"
              allowFullScreen
            />
          )}
          <h1>
            <strong>Think, play, participate</strong>
          </h1>
        </div>
      </figure>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
