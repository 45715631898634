/**
 * Body video block.
 * @module components/manage/Blocks/Video/Body
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isInternalURL } from '@plone/volto/helpers';
import { getFieldURL } from '@eeacms/volto-nextcloud-video-block/helpers';
import players from './players';

/**
 * Body video block class.
 * @class Body
 * @extends Component
 */
const Body = ({ data }) => {
  let videoID = null;

  if (data.url.match('list')) {
    const matches = data.url.match(/^.*\?list=(.*)|^.*&list=(.*)$/);
    listID = matches[1] || matches[2];
  } else {
    videoID = data.url.match(/.be\//)
      ? data.url.match(/^.*\.be\/(.*)/)[1]
      : data.url.match(/^.*\?v=(.*)$/)[1];
  }
  return (
    <>
      {data?.url && (
        <figure
          className={cx('video-inner', {
            'full-width': data.align === 'full',
          })}
        >
          <iframe
            src={`https://www.youtube.com/embed/${videoID}?autoplay=${
              data.autoPlay ? 1 : 0
            }&mute=1&loop=${data?.loop ? 1 : 0}&controls=0`}
            allow="autoplay"
          />
        </figure>
      )}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;
