import CarouselTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/CarouselTemplate';
import EventsGrid from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/EventsGrid';
import GridTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/GridTemplate';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';
import { composeSchema } from '@plone/volto/helpers';

const BG_COLORS = [{ name: 'transparent', label: 'Transparent' }];

const applyConfig = (config) => {
  config.blocks.blocksConfig.nextCloudVideo = {
    ...config.blocks.blocksConfig.nextCloudVideo,
    whiteList: ['https://www.youtube.com'],
    title: 'Video/Image (Header)',
  };
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    colors: BG_COLORS,
    schemaEnhancer: defaultStylingSchema,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'carousel',
        title: 'Carousel',
        template: CarouselTemplate,
      },
    ],
  };
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      GridTemplate.schemaEnhancer,
    ),
    allowed_headline_tags: [['h2', 'h2']],
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'events_grid',
        title: 'Events Grid',
        template: EventsGrid,
        schemaEnhancer: EventsGrid.schemaEnhancer,
      },
    ],
  };
  return config;
};

export default applyConfig;
