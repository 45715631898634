function Popup({ data, overlay }) {
  return (
    <div id="popup">
      <h1 className="popover-header">Popup</h1>
      <h1>{data}</h1>
    </div>
  );
}

export default Popup;
