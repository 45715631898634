import { useState, useEffect, useRef } from 'react';
import Map from '@eeacms/volto-openlayers-map/Map';
import { Interactions } from '@eeacms/volto-openlayers-map/Interactions';
import { Controls } from '@eeacms/volto-openlayers-map/Controls';
import { Layers, Layer } from '@eeacms/volto-openlayers-map/Layers';
import { Overlays } from '@eeacms/volto-openlayers-map/Overlays';
import { openlayers } from '@eeacms/volto-openlayers-map';
import Popup from './Popup';

const View = (props) => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureProperties, setSelectedFeatureProperties] =
    useState(null);
  const overlayPopup = useRef(null);
  const { style, proj } = openlayers;
  const image = new style.Circle({
    radius: 10,
    fill: new style.Fill({ color: 'rgba(255, 0, 0, 0.1)' }),
    stroke: new style.Stroke({ color: 'red', width: 5 }),
  });

  const styles = {
    Point: new style.Style({
      image: image,
    }),
  };
  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };
  const geojsonObject = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3857',
      },
    },
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [4e6, -2e6],
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
      },
    ],
  };

  const source = new openlayers.source.Vector({
    features: new openlayers.format.GeoJSON().readFeatures(geojsonObject),
  });
  const [tileWMSSources, setTileWMSSources] = useState([]);

  const onClickEvent = (e) => {
    const pixelTolerance = 10;
    let clickedFeature = null;
    e.map.forEachFeatureAtPixel(
      e.pixel,
      (feature) => {
        clickedFeature = feature;
        return true; // Stop iterating after the first found feature
      },
      {
        hitTolerance: pixelTolerance,
      },
    );

    if (clickedFeature) {
      const featuresProperties = clickedFeature.getProperties();
      setSelectedFeature(clickedFeature);
      setSelectedFeatureProperties(featuresProperties);

      overlayPopup.current.setPosition(e.coordinate);
    } else {
      setSelectedFeature(null);
      setSelectedFeatureProperties(null);
      overlayPopup.current.setPosition(undefined);
    }
  };

  useEffect(() => {
    if (__SERVER__) return;
    setTileWMSSources([
      new openlayers.source.TileWMS({
        url: 'https://gisco-services.ec.europa.eu/maps/service',
        params: {
          LAYERS: 'NaturalEarth',
          TILED: true,
        },
        serverType: 'geoserver',
        transition: 0,
      }),
    ]);
  }, []);

  return (
    <div className="events-map-wrapper">
      <div className="events-map" id="events-map">
        <Map
          view={{
            center: openlayers.proj.fromLonLat([0, 0]),
            showFullExtent: true,
            zoom: 5,
          }}
          pixelRatio={1}
          onClick={onClickEvent}
        >
          <Layers>
            <Layer.Tile source={tileWMSSources[0]} zIndex={0} />
            <Layer.Vector source={source} style={styleFunction} zIndex={1} />
          </Layers>
          <Overlays
            positioning="center-center"
            stopEvent={true}
            ref={(overlayP) =>
              (overlayPopup.current = {
                setPosition: (x) => {
                  return x;
                },
              })
            }
          >
            {selectedFeature && (
              <Popup
                data={selectedFeatureProperties?.geometry?.flatCoordinates}
                overlay={overlayPopup}
              />
            )}
          </Overlays>
          <Controls attribution={true} />
          <Interactions pointer={true} select={true} />
        </Map>
      </div>
    </div>
  );
};

export default View;
